import React from "react";
import "./Construction.css";
import logo from '../../images/uware.png';

function Construction() {
    return (
        <div className="Construction">
            <div>
                <img src={logo} className="Construction-img"/>
            </div>
            <div className="Construction-text">
                <p>Em Construção...</p>
            </div>
        </div>
    )
}

export default Construction;